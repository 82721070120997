@import 'ascenda-go-typefaces';
@import '../../../../libs/core/src/lib/stylesheets/core.module.typography';
@import '../../../../libs/shared-legacy/src/lib/stylesheets/shared.module.typography';
@import 'wl-typography-utils';
@import 'map-utils';

$base-typography: (
  // Desktop
  'heading-1-desktop': map-get($ascenda-go-typefaces, 'heading-50-bold'),
  'heading-2-desktop': map-get($ascenda-go-typefaces, 'heading-44-bold'),
  'heading-3-desktop': map-get($ascenda-go-typefaces, 'heading-21-bold'),
  'emphasis-1-desktop': map-get($ascenda-go-typefaces, 'body-38-regular'),
  'emphasis-2-desktop': map-get($ascenda-go-typefaces, 'body-28-light'),
  'emphasis-3-desktop': map-get($ascenda-go-typefaces, 'body-30-light'),
  'emphasis-4-desktop': map-get($ascenda-go-typefaces, 'body-24-light'),
  'emphasis-5-desktop': map-get($ascenda-go-typefaces, 'body-22-regular'),
  'emphasis-6-desktop': map-get($ascenda-go-typefaces, 'body-20-regular'),
  'emphasis-7-desktop': map-get($ascenda-go-typefaces, 'body-20-light'),
  'emphasis-8-desktop': map-get($ascenda-go-typefaces, 'body-18-regular'),
  'emphasis-9-desktop': map-get($ascenda-go-typefaces, 'body-18-light'),
  'text-desktop': map-get($ascenda-go-typefaces, 'text-16-light'),
  'text-emphasis-1-desktop': map-get($ascenda-go-typefaces, 'text-16-bold'),
  'text-emphasis-2-desktop': map-get($ascenda-go-typefaces, 'text-16-regular'),
  'small-text-desktop': map-get($ascenda-go-typefaces, 'small-text-14-light'),
  'small-text-emphasis-1-desktop':
    map-get($ascenda-go-typefaces, 'small-text-14-bold'),
  'small-text-emphasis-2-desktop':
    map-get($ascenda-go-typefaces, 'small-text-14-regular'),
  'caption-desktop': map-get($ascenda-go-typefaces, 'caption-12-regular'),
  'caption-emphasis-desktop': map-get($ascenda-go-typefaces, 'caption-12-bold'),
  // Mobile
  'heading-1-mobile': map-get($ascenda-go-typefaces, 'heading-24-bold'),
  'heading-2-mobile': map-get($ascenda-go-typefaces, 'heading-32-bold'),
  'heading-3-mobile': map-get($ascenda-go-typefaces, 'caption-12-bold'),
  'emphasis-1-mobile': map-get($ascenda-go-typefaces, 'body-22-regular'),
  'emphasis-2-mobile': map-get($ascenda-go-typefaces, 'text-16-light'),
  'emphasis-3-mobile': map-get($ascenda-go-typefaces, 'body-22-light'),
  'emphasis-4-mobile': map-get($ascenda-go-typefaces, 'body-22-regular'),
  'emphasis-5-mobile': map-get($ascenda-go-typefaces, 'text-16-bold'),
  'emphasis-6-mobile': map-get($ascenda-go-typefaces, 'text-16-regular'),
  'emphasis-7-mobile': map-get($ascenda-go-typefaces, 'text-16-light'),
  'emphasis-8-mobile': map-get($ascenda-go-typefaces, 'text-16-regular'),
  'emphasis-9-mobile': map-get($ascenda-go-typefaces, 'text-16-light'),
  'text-mobile': map-get($ascenda-go-typefaces, 'text-16-light'),
  'text-emphasis-1-mobile': map-get($ascenda-go-typefaces, 'text-16-bold'),
  'text-emphasis-2-mobile': map-get($ascenda-go-typefaces, 'text-16-regular'),
  'small-text-mobile': map-get($ascenda-go-typefaces, 'small-text-14-light'),
  'small-text-emphasis-1-mobile':
    map-get($ascenda-go-typefaces, 'small-text-14-bold'),
  'small-text-emphasis-2-mobile':
    map-get($ascenda-go-typefaces, 'small-text-14-regular'),
  'caption-mobile': map-get($ascenda-go-typefaces, 'caption-12-regular'),
  'caption-emphasis-mobile': map-get($ascenda-go-typefaces, 'caption-12-bold'),
  'caption-light': map-get($ascenda-go-typefaces, 'caption-12-light')
);

$core-module-typography: ();

$shared-module-typography: ();

$redeem-cash-module-typography: (
  'rcv2-checkout-banner--banner-title-typeface-desktop':
    map-get($base-typography, 'heading-2-mobile'),
  'rcv2-checkout-banner--banner-title-typeface-mobile':
    map-get($base-typography, 'heading-2-mobile'),
  'rcv2-checkout-banner--banner-subtitle-typeface-desktop':
    map-get($base-typography, 'text-emphasis-2-desktop'),
  'rcv2-checkout-banner--banner-subtitle-typeface-mobile':
    map-get($base-typography, 'text-emphasis-2-mobile'),
  'rcv2-checkout-terms-list--list-item-text-typeface':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-checkout-terms-list--reminders-container-typeface':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-checkout-information--title-typeface-desktop':
    map-get($base-typography, 'heading-1-mobile'),
  'rcv2-checkout-information--title-typeface-mobile':
    map-get($base-typography, 'heading-1-mobile'),
  'rcv2-checkout-information--content-typeface-desktop':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-checkout-information--content-typeface-mobile':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-checkout-form--title-typeface-desktop':
    map-get($base-typography, 'heading-1-mobile'),
  'rcv2-checkout-form--title-typeface-mobile':
    map-get($base-typography, 'heading-1-mobile'),
  'rcv2-checkout-transfer-amount-form--amount-label-typeface-desktop':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-checkout-transfer-amount-form--amount-label-typeface-mobile':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-checkout-transfer-amount-form--mat-hint-typeface':
    map-get($base-typography, 'caption-light'),
  'rcv2-checkout-linked-account--linked-account-label-typeface':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-checkout-linked-account--linked-account-number-typeface-desktop':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-checkout-linked-account--linked-account-number-typeface-mobile':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-checkout-linked-account--change-linked-account-text-typeface':
    map-get($base-typography, 'small-text-emphasis-2-mobile'),
  'rcv2-membership-success-dialog--title-typeface':
    map-get($base-typography, 'heading-2-mobile'),
  'rcv2-membership-success-dialog--description-typeface':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-membership-failed-dialog--title-typeface':
    map-get($base-typography, 'heading-2-mobile'),
  'rcv2-membership-failed-dialog--description-typeface':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-review-body--title-typeface-desktop':
    map-get($base-typography, 'heading-2-mobile'),
  'rcv2-review-body--title-typeface-mobile':
    map-get($base-typography, 'heading-2-mobile'),
  'rcv2-review-breakdown--list-item-description-typeface-desktop':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-review-breakdown--list-item-description-typeface-mobile':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-review-breakdown--list-item-amount-typeface-desktop':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-review-breakdown--list-item-amount-typeface-mobile':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-review-body--helper-text-desktop':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-review-body--helper-text-mobile':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-review-body--redeem-account-text-typeface-desktop':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-review-body--redeem-account-text-typeface-mobile':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-success-points-transaction-status--status-text-typeface-desktop':
    map-get($base-typography, 'heading-2-mobile'),
  'rcv2-success-points-transaction-status--status-text-typeface-mobile':
    map-get($base-typography, 'heading-2-mobile'),
  'rcv2-success-points-transaction-status--order-id-typeface-desktop':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-success-points-transaction-status--order-id-typeface-mobile':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-success-points-transaction-status--cash-disclaimer-typeface-desktop':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-success-points-transaction-status--cash-disclaimer-typeface-mobile':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-success-detail--submitted-on-typeface':
    map-get($base-typography, 'emphasis-6-mobile'),
  'rcv2-success-detail--deliver-message-reminder':
    map-get($base-typography, 'emphasis-6-mobile'),
);

$redeem-cash-module-helvetica-typography: (
  'rcv2-checkout-transfer-amount-form--mat-input-typeface':
    map-get($base-typography, 'emphasis-6-mobile'),
);

$transfer-connect-module-typography: ();

$popup-module-typography: ();

$tenant-typography: map-collect(
  $core-module-typography,
  $shared-module-typography,
  $transfer-connect-module-typography,
  $popup-module-typography
);

@include rd-core-module-typography($base-typography, $tenant-typography);
@include rd-shared-module-typography($base-typography, $tenant-typography);

:root {
  @include generate-typography-variable-from-map(
    $base-typography,
    'Muli, sans-serif'
  );

  @include generate-typography-variable-from-map(
    $redeem-cash-module-typography,
    'Muli, sans-serif'
  );
  @include generate-typography-variable-from-map(
    $redeem-cash-module-helvetica-typography,
    'Helvetica, Muli, sans-serif'
  );
}
