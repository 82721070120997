:root {
  --ascenda-go-deep-cloud-burst: #1a1e43;
  --ascenda-go-cloud-burst: #22285a;
  --ascenda-go-light-cloud-burst: #595e83;
  --ascenda-go-sheer-cloud-burst: #9193ac;
  --ascenda-go-bg-cloud-burst: #e6e8f1;

  --ascenda-go-gray-600: #494949;
  --ascenda-go-gray-500: #747678;
  --ascenda-go-gray-400: #cccccc;
  --ascenda-go-gray-200: #eeeeee;
  --ascenda-go-gray-100: #f7f7f7;

  --ascenda-go-deep-shakespeare: #04789d;
  --ascenda-go-shakespeare: #05a0d1;
  --ascenda-go-light-shakespeare: #43b8dc;
  --ascenda-go-sheer-shakespeare: #82cfe8;
  --ascenda-go-bg-shakespeare: #e6f6fa;

  --ascenda-go-warning: #ffb800;
  --ascenda-gogogo-warning-transparent: #ffb80020;
  --ascenda-go-error: #ce1818;
  --ascenda-go-error-transparent: #ce181820;
  --ascenda-go-success: #19ae67;
  --ascenda-go-success-transparent: #19ae6720;
  --ascenda-go-border: #e0e0e0;

  --ascenda-go-outline: #9da4d9;

  --ascenda-go-tab-label-color: #20285d;
  --ascenda-go-tab-highlight-color: #20285d;
}

$white: #ffffff;

$black: #000;

$red: #b10000;

$blue-100: #f2f2f5;
$blue-200: #05a0d1;
$blue-300: #20285d;
$blue-400: #141637;

$cyan-100: #f2fafd;
$cyan-200: #4991d0;

$shadow: $black;

$grey-100: #fafafa;
$grey-200: #ededed;
$grey-300: #e0e0e0;
$grey-400: #c1c1c1;
$grey-500: #4a4a4a;

$green-100: #abeab6;
$green-200: #3dbc86;

$orange: #e09b00;

$link-blue: #4991d0;
