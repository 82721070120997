@use '@angular/material' as mat;
@import 'rd-global';
@import './ascenda-go-fonts';
@import './ascenda-go-theme';
@import './ascenda-go-typography';
@import './ascenda-go-tenant-variables';
@import 'third-party-libs/third-party-libs';

@import './ascenda-go-material-theme';

@include mat.all-component-typographies();
@include mat.core();

$ascenda-go-primary: mat.define-palette($primary-theme, 100, 200, 300, 400);
$ascenda-go-accent: mat.define-palette($accent-theme, 100, 200, 300, 400);

// The warn palette is optional (defaults to red).
$ascenda-go-warn: mat.define-palette($warn-theme);

// Create the theme object (a Sass map containing all of the palettes).
$ascenda-go-theme: mat.define-light-theme(
  $ascenda-go-primary,
  $ascenda-go-accent,
  $ascenda-go-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ascenda-go-theme);

$custom-typography: mat.define-typography-config(
  $font-family: 'Muli, sans-serif',
);

@include mat.tabs-typography($custom-typography);

body {
  background-color: $grey-100;
}

body,
button,
input,
.pika-single {
  font-family: Muli, sans-serif;
}

html,
body {
  height: 100%;
}

[override] {
  .rd-tc-checkout .rd-tc-checkout-form img.loyalty-program-image {
    object-fit: contain;
  }

  .rd-tc-review-body .loyalty-program-image-container .loyalty-program-image {
    object-fit: contain;
  }

  .rd-pt-chart-legend .text {
    word-break: normal;
  }

  .mat-mdc-floating-label.mdc-floating-label--float-above {
    top: 18px !important;
  }

  .rd-rcv2-checkout-form {
    .rd-shared-counter {
      input.mat-mdc-input-element {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
        line-height: 1;
      }

      .rd-shared-icon.plus-icon,
      .rd-shared-icon.minus-icon {
        background: transparent;
        .icon-fill {
          fill: transparent;
        }

        &.disabled {
          background: transparent;
          .icon-fill {
            fill: transparent;
          }
        }
      }

      .rd-shared-icon.icon-active {
        &.plus-icon:active,
        &.minus-icon:active {
          background-color: transparent;
        }

        &.plus-icon.disabled:active,
        &.minus-icon.disabled:active {
          background-color: transparent;
        }
      }
    }
  }
}
