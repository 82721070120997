$cdn-url: 'https://d2xr67b5foriew.cloudfront.net/fonts';

@font-face {
  font-family: 'Muli';
  font-weight: 100;
  font-style: light;
  src: url('#{$cdn-url}/Muli-Light.eot');
  src: url('#{$cdn-url}/Muli-Light.eot?#iefix') format('embedded-opentype'),
    url('#{$cdn-url}/Muli-Light.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-Light.woff') format('woff'),
    url('#{$cdn-url}/Muli-Light.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-Light.svg#Muli-Light') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 100;
  font-style: italic;
  src: url('#{$cdn-url}/Muli-ThinItalic.eot');
  src: url('#{$cdn-url}/Muli-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$cdn-url}/Muli-ThinItalic.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-ThinItalic.woff') format('woff'),
    url('#{$cdn-url}/Muli-ThinItalic.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-ThinItalic.svg#Muli-ThinItalic') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 300;
  font-style: normal;
  src: url('#{$cdn-url}/Muli-Light.eot');
  src: url('#{$cdn-url}/Muli-Light.eot?#iefix') format('embedded-opentype'),
    url('#{$cdn-url}/Muli-Light.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-Light.woff') format('woff'),
    url('#{$cdn-url}/Muli-Light.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-Light.svg#Muli-Light') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 300;
  font-style: italic;
  src: url('#{$cdn-url}/Muli-LightItalic.eot');
  src: url('#{$cdn-url}/Muli-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$cdn-url}/Muli-LightItalic.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-LightItalic.woff') format('woff'),
    url('#{$cdn-url}/Muli-LightItalic.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-LightItalic.svg#Muli-LightItalic') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 400;
  font-style: normal;
  src: url('#{$cdn-url}/Muli-Regular.eot');
  src: url('#{$cdn-url}/Muli-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$cdn-url}/Muli-Regular.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-Regular.woff') format('woff'),
    url('#{$cdn-url}/Muli-Regular.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-Regular.svg#Muli-Regular') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 400;
  font-style: italic;
  src: url('#{$cdn-url}/Muli-Italic.eot');
  src: url('#{$cdn-url}/Muli-Italic.eot?#iefix') format('embedded-opentype'),
    url('#{$cdn-url}/Muli-Italic.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-Italic.woff') format('woff'),
    url('#{$cdn-url}/Muli-Italic.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-Italic.svg#Muli-Italic') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 500;
  font-style: normal;
  src: url('#{$cdn-url}/Muli-Medium.eot');
  src: url('#{$cdn-url}/Muli-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$cdn-url}/Muli-Medium.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-Medium.woff') format('woff'),
    url('#{$cdn-url}/Muli-Medium.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-Medium.svg#Muli-Medium') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 500;
  font-style: italic;
  src: url('#{$cdn-url}/Muli-MediumItalic.eot');
  src: url('#{$cdn-url}/Muli-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$cdn-url}/Muli-MediumItalic.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-MediumItalic.woff') format('woff'),
    url('#{$cdn-url}/Muli-MediumItalic.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-MediumItalic.svg#Muli-MediumItalic') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 600;
  font-style: normal;
  src: url('#{$cdn-url}/Muli-Bold.eot');
  src: url('#{$cdn-url}/Muli-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$cdn-url}/Muli-Bold.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-Bold.woff') format('woff'),
    url('#{$cdn-url}/Muli-Bold.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-Bold.svg#Muli-Bold') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 600;
  font-style: italic;
  src: url('#{$cdn-url}/Muli-BoldItalic.eot');
  src: url('#{$cdn-url}/Muli-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$cdn-url}/Muli-BoldItalic.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-BoldItalic.woff') format('woff'),
    url('#{$cdn-url}/Muli-BoldItalic.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-BoldItalic.svg#Muli-BoldItalic') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 700;
  font-style: normal;
  src: url('#{$cdn-url}/Muli-Bold.eot');
  src: url('#{$cdn-url}/Muli-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$cdn-url}/Muli-Bold.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-Bold.woff') format('woff'),
    url('#{$cdn-url}/Muli-Bold.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-Bold.svg#Muli-Bold') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 700;
  font-style: italic;
  src: url('#{$cdn-url}/Muli-BoldItalic.eot');
  src: url('#{$cdn-url}/Muli-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('#{$cdn-url}/Muli-BoldItalic.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-BoldItalic.woff') format('woff'),
    url('#{$cdn-url}/Muli-BoldItalic.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-BoldItalic.svg#Muli-ExtraBoldItalic') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 900;
  font-style: normal;
  src: url('#{$cdn-url}/Muli-Black.eot');
  src: url('#{$cdn-url}/Muli-Black.eot?#iefix') format('embedded-opentype'),
    url('#{$cdn-url}/Muli-Black.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-Black.woff') format('woff'),
    url('#{$cdn-url}/Muli-Black.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-Black.svg#Muli-Black') format('svg');
}

@font-face {
  font-family: 'Muli';
  font-weight: 900;
  font-style: italic;
  src: url('#{$cdn-url}/Muli-BlackItalic.eot');
  src: url('#{$cdn-url}/Muli-BlackItalic.eot?#iefix')
      format('embedded-opentype'),
    url('#{$cdn-url}/Muli-BlackItalic.woff2') format('woff2'),
    url('#{$cdn-url}/Muli-BlackItalic.woff') format('woff'),
    url('#{$cdn-url}/Muli-BlackItalic.ttf') format('truetype'),
    url('#{$cdn-url}/Muli-BlackItalic.svg#Muli-ExtraBlackItalic') format('svg');
}
